import React, { useState, useEffect, useRef } from "react";
// import { occurrences } from "../../../Utils";
import packageJson from "../../../../package.json";
// import SearchBar from "../search/SearchBar";
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import MobileMenu from "./MobileMenu/MobileMenu";
import { useTranslations } from "../../../lang/TranslationHooks";

import "./Menu.css";
import { useSelector } from "react-redux";

const Menu = ({ mobile, pageName, home }) => {
  const { sponsors } = useSelector(state => state.home);

  let { staticTranslate } = useTranslations();

  // const menuDataBg = useMemo(() => [
  //   {
  //     key: "section00",
  //     title: staticTranslate('t_menu_section_1_title', true),
  //   },
  //   {
  //     key: "section01",
  //     title: staticTranslate('t_menu_section_2_title', true),

  //   },
  //   {
  //     key: "section02",
  //     title: staticTranslate('t_menu_section_5_title', true),
  //     redirectToPage: 'https://feia.bg/community/',
  //   },
  //   {
  //     key: "section03",
  //     title: staticTranslate('t_feia_blog', true),
  //     redirectToPage: 'https://feia.bg/shop/blog-site/'
  //   },
  //   {
  //     key: "section04",
  //     title: staticTranslate('t_menu_section_3_title', true),
  //     redirectToPage: 'https://feia.bg/shop/'
  //   },
  //   {
  //     key: "section05",
  //     title: staticTranslate('t_menu_section_6_title', true),
  //   }
  // ], [staticTranslate]);

  const internationData = [
    {
      key: "section00",
      title: staticTranslate('t_menu_international_title_1', true),
    },
    // {
    //   key: "section01",
    //   title: staticTranslate('t_menu_international_title_2', true),
    // },
    {
      key: "section02",
      title: staticTranslate('t_menu_international_title_3', true),
    },
    {
      key: "section03",
      title: staticTranslate('t_menu_international_title_4', true),
    },
    {
      key: "section04",
      title: staticTranslate('t_menu_international_title_5', true),
    },
    {
      key: "section05",
      title: staticTranslate('t_feia_blog', true),
      redirectToPage: 'https://feia.bg/shop/blog-site/'
    },
    {
      key: "section06",
      title: staticTranslate('t_menu_international_title_6', true),
    },
  ];
  const [activeSection, setActiveSection] = useState("section00");
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [appsExpand, setAppsExpand] = useState(true);
  const [scrollStatus, setScrollStatus] = useState("");
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const [changeNavbarBg, setChangeNavbarBg] = useState(false);
  const [currentHeight, setCurrentHeight] = useState(0);
  const [_timeout, set_timeout] = useState(null);
  let menuData = internationData

  const container = useRef();

  const handleClickOutside = (event) => {
    if (
      container.current &&
      !container.current.contains(event.target) &&
      !(event.target.className === "openHamburgerIcon")
    ) {
      setMenuIsOpened(false);
      changeNavBarBackground(currentHeight);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside)
  });

  useEffect(() => {
    const handleScroll = (e) => {
      const sections = menuData;
      let curViewId = activeSection;
      setCurrentHeight(e.target.scrollTop);
      backToTopButton(e.target.scrollTop);

      const curView = document.getElementById(curViewId);
      if (!curView) {
        return;
      }

      changeNavBarBackground(e.target.scrollTop);

      const curViewTopPosition = curView?.getBoundingClientRect()?.top;
      sections.forEach((s) => {
        const domObject = document.getElementById(s.key);
        const topPosition = domObject?.getBoundingClientRect()?.top;

        if (Math.abs(topPosition) < Math.abs(curViewTopPosition)) {
          curViewId = s.key;
        }
      });

      if (_timeout) {
        // If there is already a timeout in process, cancel it
        clearTimeout(_timeout);
      }

      set_timeout(
        setTimeout(() => {
          set_timeout(null);
          // Scroll stopped
          if (activeSection !== curViewId) {
            // Set visible page id
            setActiveSection(curViewId);
            setScrollStatus("scrolling");
            // Use for snap into the current page after scrolling ends
            // setTimeout(() => {
            //   scrollToSection(curViewId);
            // }, 1000);
          } else {
            setActiveSection(curViewId);
            setScrollStatus("scroll stopped");
          }
        }, 8)
      );

      if (scrollStatus !== "scrolling") {
        // Scroll
        setScrollStatus("scrolling");
      }
    };

    document.getElementById("scrollContainer").addEventListener("scroll", handleScroll);

    return () => {
      document.getElementById("scrollContainer").removeEventListener("scroll", handleScroll);
    };
  }, [activeSection, scrollStatus, _timeout, menuData]);


  const changeNavBarBackground = (target) => {
    if (target > 0) {
      setChangeNavbarBg(true);
    } else {
      setChangeNavbarBg(false);
    }
  };

  const scrollToSection = (id) => {
    let anchorTarget = document.getElementById(id);
    if (anchorTarget) {
      anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
      document.getElementById("scrollContainer").scrollTo({
        left: 0,
        top: anchorTarget.offsetTop,
        behavior: "smooth",
      });
      setActiveSection(id);
    }
  };

  const backToTopButton = (currentHeight) => {
    if (currentHeight <= 500) {
      setShowBackToTopButton(false);
    } else {
      setShowBackToTopButton(true);
    }
  };

  const onMenuClicked = (e) => {
    setMenuIsOpened(!menuIsOpened);
    if (!menuIsOpened) {
      setChangeNavbarBg(true);
    } else {
      changeNavBarBackground(currentHeight);
    }
  };


  if (mobile) {
    return (
      <MobileMenu
        menuIsOpened={menuIsOpened}
        changeNavbarBg={changeNavbarBg}
        appsExpand={appsExpand}
        showBackToTopButton={showBackToTopButton}
        sections={sponsors?.length > 0 ? menuData : menuData.slice(0, -1)}
        packageJson={packageJson}
        pageName={pageName}
        onMenuClicked={onMenuClicked}
        container={container}
        currentHeight={currentHeight}
        updateAppsExpand={() => setAppsExpand(!appsExpand)}
        scrollToSection={scrollToSection}
        activeSection={activeSection}
      />
    );
  } else {
    return (
      <DesktopMenu
        packageJson={packageJson}
        sections={sponsors?.length > 0 ? menuData : menuData.slice(0, -1)}
        activeSection={activeSection}
        pageName={pageName}
        currentHeight={currentHeight}
        showBackToTopButton={showBackToTopButton}
        scrollToSection={scrollToSection}
        changeNavbarBg={changeNavbarBg}
        home={home}
      />
    );
  }
};

export default Menu;
