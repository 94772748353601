import React, { useRef, useEffect } from 'react';
import { className, removEmptyParagraph } from '../../../Utils';
import { faq_Arrow, faq_Arrow_up } from '../../../Images';
import './SingleFAQItem.css';

function getScrollableParent(node) {
    if (node == null) {
        return null;
    }
    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollableParent(node.parentNode);
    }
}

const SingleFAQItem = ({ id, title, content, clicked, toggle, index }) => {
    const faqRef = useRef(null);


    // Scroll to the element if it's clicked and active
    useEffect(() => {
        if (clicked === id && faqRef.current) {
            const offset = 100;

            // Find the nearest scrollable parent
            const scrollableParent = getScrollableParent(faqRef.current) || window;

            // Calculate the element's position relative to the scrollable parent
            const elementTop = faqRef.current.getBoundingClientRect().top + scrollableParent.scrollTop;
            const scrollPosition = elementTop - offset;

            // Scroll the parent or window to the calculated position
            scrollableParent.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    }, [clicked, id]);

    return (
        <div
            ref={faqRef}
            onClick={() => toggle(id)}
            className={className('single-faq-item-wrapper') + ' ' + className(`${clicked === id && 'single-faq-item-wrapper-active'}`)}
        >
            <div className={className('single-faq-container') + ' ' + className(`${clicked === id && 'single-faq-container-active'}`)}>
                <p>
                    {index + 1}. {title}
                </p>
                <div className={className(clicked === id ? 'faq-arrow-container-active' : 'faq-arrow-container')}>
                    <img src={clicked === id ? faq_Arrow_up : faq_Arrow} alt="arrow" />
                </div>
            </div>
            {clicked === id && (
                <div
                    className={className('single-faq-item-content')}
                    dangerouslySetInnerHTML={{
                        __html: removEmptyParagraph(content),
                    }}
                />
            )}
        </div>
    );
};

export default SingleFAQItem;
