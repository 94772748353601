import React from 'react'
import AddPriceSection from '../../AddPriceSection/AddPriceSection'

import { className } from '../../../Utils'

import './AddPriceContainer.css'

const AddPriceContainer = ({ sections }) => {
    return (
        <div className={className('add-price-container')} id='add-price-container'>
            <AddPriceSection sections={sections} />
        </div>
    )
}

export default AddPriceContainer