import React from 'react'
import { HashLink } from 'react-router-hash-link';

import { Link } from "react-router-dom";

const ScrollIndicatorWrapper = ({ sections, activeSection, pageName, }) => {

    return (
        <div className="scroll-indicators-wrapper">
            <div className={"dashesPath"} />
            {sections.map((page) => {
                // if (page.innerPages) {
                //     return (
                //         <React.Fragment key={page.key}>
                //             <div
                //                 className={`indicator${pageName === 'home' && activeSection === page.key
                //                     ? home ? " active" : " "
                //                     : pageName !== 'home'
                //                         ? page.feia_page_name !== 'our_apps'
                //                             ? " active" : ' '
                //                         : ''
                //                     }`}
                //                 key={page.key}

                //                 onClick={(e) => scrollToSection(pageName === 'home' && page.key)}
                //             >{console.log(page)}
                //                 <a href={`/#${page.key}`} >
                //                     <span>{page.title}</span>
                //                 </a>
                //             </div>
                //             <div className={"pageCluster"}>
                //                 {page.innerPages.map((innerPage) => (
                //                     <Link key={innerPage.key} to={`/${innerPage.key}`}>
                //                         <div
                //                             className={'indicator' + (pageName !== 'home'
                //                                 && innerPage.key === pageName
                //                                 ? ' active'
                //                                 : '')}
                //                         >
                //                             <span>{innerPage.title}</span>
                //                         </div>
                //                     </Link>
                //                 ))}
                //             </div>
                //         </React.Fragment>
                //     );
                // }
                return (
                    <div key={page.key} className="dots-wrapper" >
                        <HashLink to={'/'}
                            elementId={page.key}
                            scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: "start" })}
                            title={page.title}
                        >
                            <div
                                className={`indicator${(pageName === 'pregnancy')
                                    ? activeSection === page.key
                                        ? " active" : ``
                                    : pageName === page?.feia_page_name?.slice(1) ? ` active` : ' '
                                    }`}
                            >
                            </div>
                        </HashLink >
                        {
                            //redirect to site page
                            page.feia_web_page
                                ? <Link to={page.feia_page_name}>
                                    <span className={`dots-wrapper__text${pageName === 'pregnancy'
                                        && activeSection === page.key ? " active-text" : ``}`}
                                        title={page.title}
                                    >
                                        {page.title}
                                    </span>
                                </Link>
                                //redirect to the external page
                                : <HashLink to={pageName !== 'pregnancy'
                                    && page.redirectToPage
                                    ? `/${pageName}`
                                    : '/'
                                }
                                    elementId={!page.redirectToPage ? page.key : ''}
                                    title={page.title}
                                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: "start" })}

                                >
                                    <span className={`dots-wrapper__text${pageName === 'pregnancy'
                                        && activeSection === page.key ? " active-text" : ``}`}
                                        onClick={(e) => page.redirectToPage
                                            && window.open(`${page.redirectToPage}`, "_blank")
                                        }
                                    >
                                        {page.title}
                                    </span>
                                </HashLink >
                        }
                    </div>
                );
            })
            }
        </div>
    )
}

export default ScrollIndicatorWrapper