import { isMobile } from 'react-device-detect';
import { className } from '../../../Utils';
import { useLocation } from 'react-router-dom';

import { downClouds } from '../../../Images';
import './CarouselClouds.css'
import { useSelector } from 'react-redux';

const CarouselClouds = () => {
    let location = useLocation();
    const { language } = useSelector((state) => state.home)

    return <div className={className('carousel-image-wrapper')}>
        {(location.pathname === '/')
            && isMobile && <div className={className('carousel-image-container-home')} alt="downClouds" >
                <img
                    src={downClouds}
                    className={className('carousel-image-container-home-image')}
                    alt="downClouds"
                    loading='lazy'
                />
            </div>
        }
        {!isMobile &&
            (language === 'bg' && location.pathname === '/')
            &&
            < img
                src={downClouds}
                className={className('carousel-image-container-pregnancy-desktop')}
                alt="downClouds"
                loading='lazy'
            />
        }
    </div>
};

export default CarouselClouds;