import React from "react";
import { Link } from "react-router-dom";

import SocialMediaContainer from "./SocialMediaContainer";
import OperationSystem from "../../../operationSystem/OperationSystem";

import ScrollIndicatorWrapper from "./ScrollIndicatorWrapper";
import { useSelector } from "react-redux";

import { logo } from "../../../../Images";
import gaiaLogo from '../../../../assets/GaiaLogo.png'
import CountryPicker from "../CountryPicker/CountryPicker";

/* hide those components while the community page is not ready */
// import NotifIcon from '../../../User/Notifications/NotifIcon';
// import UserIcon from "../../../User/UserIcon/UserIcon";

/* hide AdvertisingCard while we don't have something special to present */
// import AdvertisingCard from "../../../AdvertisingCard/AdvertisingCard";


const DesktopMenu = ({
  packageJson,
  sections,
  showBackToTopButton,
  pageName,
  activeSection,
  currentHeight,
  scrollToSection,
  changeNavbarBg,
  home
}) => {

  const { language } = useSelector((state) => state.home)
  return (
    <div className="scroll-indicator-controller">
      {/* <SearchBar
             size={3}
            iconBackground={"#b171b3"}
            onSearch={(word, idx) => this.findSector(word, idx)}
          /> */}

      {/* hide this components while the community page is not ready */}
      {/* {language === 'bg' && <UserIcon background={changeNavbarBg} />} */}


      {!(pageName === "home") && (
        <OperationSystem currentHeight={currentHeight} />
      )}

      {/* hide AdvertisingCard while we don't have something special to present */}
      {/* <div>
        {(pageName === "home" || pageName === "pregnancy") && (
          <AdvertisingCard currentHeight={currentHeight} pageName={pageName} />
        )}
      </div> */}

      <Link to='/' title={language === 'bg' ? 'Feia Logo' : 'Gaia Logo'}>
        <img
          src={language === 'bg' ? logo : gaiaLogo}
          className={language === 'bg' ? 'Feia-logo-menu' : 'gaia-logo-menu'}
          alt="logo"
          onClick={() => (pageName === "pregnancy" || language !== 'bg') && scrollToSection("section00")}
          loading="lazy"
        />
      </Link>

      <ScrollIndicatorWrapper
        pageName={pageName}
        sections={sections}
        activeSection={activeSection}
        scrollToSection={scrollToSection}
        home={home}
      />

      <SocialMediaContainer />
      <CountryPicker />
      {showBackToTopButton && (
        <div
          className={"chevronUp descktop-chevronUp"}
          onClick={() => scrollToSection("section00")}
        />
      )}
      <div className={"version"}>{packageJson.version}</div>
    </div>
  );
};

export default DesktopMenu;
